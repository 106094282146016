<template>
  <div>
    <the-breadcrumbs></the-breadcrumbs>
    <folder-details
      :items="items"
      :on-region-chosen="loadItems"
      :loading="loading"
      :show-region-chooser="true"
      page-title="Wissenswertes"
    ></folder-details>
  </div>
</template>

<script>
import TheBreadcrumbs from "@/components/TheBreadcrumbs"
import FolderDetails from "@/components/FolderDetails"
import GuideItemsRepository from "@/repositories/guide_items_repository.js"
import ApiErrorHandlerMixin from "@/mixins/api_error_handler.js"

export default {
  components: {
    TheBreadcrumbs,
    FolderDetails
  },
  mixins: [ApiErrorHandlerMixin],
  metaInfo: {
    title: "Wissenswertes"
  },
  data() {
    return {
      loading: false,
      items: []
    }
  },
  computed: {},
  async created() {
    this.loadItems()
  },
  methods: {
    async loadItems() {
      this.loading = true

      try {
        this.items = await GuideItemsRepository.getAll()
        this.loading = false
      } catch (error) {
        this.loading = false
        this.handleApiError(error)
      }
    }
  }
}
</script>
